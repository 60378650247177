import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import ReactTooltip from "react-tooltip";

// Images
import js from "../../assets/images/javascript-logo-escudo.png";
import firebase from "../../assets/images/firebase.png";
import node from "../../assets/images/nodejs.png";
import google from "../../assets/images/google-apps-script.svg";
import ionic from "../../assets/images/ionic_1307248.png";
import mysql from "../../assets/images/logo-mysql-mysql-and-moodle-elearningworld-5.png";
import pwa from "../../assets/images/PWA.png";
import psql from "../../assets/images/1200px-Postgresql_elephant.svg.png";
import aws from "../../assets/images/1024px-Amazon_Web_Services_Logo.svg.png";
import rct from "../../assets/images/1460px-React_logo.png";
import angular from "../../assets/images/angular.png";
import mongodb from "../../assets/images/mongodb.png";
import wordpress from "../../assets/images/wordpress.png";
import googleLogo from "../../assets/images/google.png";
import appSheet from "../../assets/images/appsheet.svg";
import azure from "../../assets/images/azure.svg";
import app_engine from "../../assets/images/app_engine.png";
import powerapps from "../../assets/images/powerapps.svg";

// Images
import img1 from "../../assets/images/1.png";
import img2 from "../../assets/images/4.png";
import img3 from "../../assets/images/3.png";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  const sectionHeader = {
    title: "Tecnologías aplicadas en DAXOS",
    // paragraph:
    //   "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum — semper quis lectus nulla at volutpat diam ut venenatis.",
  };

  return (
    <section {...props} className={outerClasses}>
      <ReactTooltip />
      <div className="container">
        <div className={innerClasses}>
          <div className="banner">
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className="banner_section">
              <img src={js} alt="JavaScrip" data-tip="JavaScript" />
              <img src={node} alt="NodeJS" data-tip="NodeJS" />
              <img src={google} alt="AppScript" data-tip="AppScript" />
              <img
                src={rct}
                alt="ReactJS"
                data-tip="ReactJS"
                style={{ inlineSize: "160px" }}
              />
              <img
                src={angular}
                alt="AngularJS"
                data-tip="AngularJS"
                style={{ inlineSize: "60px" }}
              />
            </div>
            <div className="banner_section">
              <img
                src={wordpress}
                alt="Wordpress"
                data-tip="Wordpress"
                style={{ inlineSize: "160px" }}
              />
              <img src={ionic} alt="Ionic" data-tip="Ionic" />
              <img
                src={appSheet}
                alt="AppSheet"
                data-tip="AppSheet"
                style={{ inlineSize: "80px" }}
              />
              <img src={pwa} alt="PWA" data-tip="PWA" />
              <img src={mongodb} alt="MongoDB" data-tip="MongoDB" />
            </div>
            <div className="banner_section">
              <img
                src={psql}
                alt="PostgresQL"
                data-tip="PostgresQL"
                style={{ inlineSize: "80px" }}
              />
              <img src={firebase} alt="Firebase" data-tip="Firebase" />
              <img src={mysql} alt="MySQL" data-tip="MySQL" />
              <img
                src={app_engine}
                alt="App Engine"
                data-tip="App Engine"
                style={{ inlineSize: "120px" }}
              />
              <img
                src={powerapps}
                alt="Powerapps"
                data-tip="Powerapps"
                style={{ inlineSize: "90px" }}
              />
            </div>
            <div className="banner_section_2">
              <img
                src={azure}
                alt="Azure"
                data-tip="Azure"
                style={{ inlineSize: "90px" }}
              />
              <img
                src={googleLogo}
                alt="Google"
                data-tip="Google"
                style={{ inlineSize: "85px" }}
              />
              <img
                src={aws}
                alt="AWS"
                data-tip="AWS"
                style={{ inlineSize: "100px" }}
              />
            </div>
          </div>
          <div className={splitClasses}>
            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <h3 className="text-color-blue fw-600 tt-u mb-8">
                  Soluciones a la medida
                </h3>
                {/* <h3 className="mt-0 mb-12 text-color-gold">
                  Data-driven insights
                </h3> */}
                <p className="m-0 ta-j">
                  Daxos es una empresa especializada en ofrecer desarrollos a la
                  medida del cliente, dando solución a diferentes
                  requerimientos, conectores, integradores entre otros, siempre
                  ofreciendo un crecimiento modular, permitiendo a nuestro
                  clientes crecer a medida que lo necesiten.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={img1}
                  alt="Features split 01"
                  width={528}
                  height={396}
                />
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item"
              >
                <h3 className=" text-color-blue fw-600 tt-u mb-8">
                  Las tres principales nubes del mercado
                </h3>
                {/* <h3 className="mt-0 mb-12 text-color-gold">
                  Data-driven insights
                </h3> */}
                <p className="m-0 ta-j">
                  Daxos se especializa en brindar soluciones en la nube con
                  personal certificado y con amplia experiencia para poder
                  ofrecer la mejor asesoría y solución a nuestros clientes.
                  Todos nuestras soluciones y desarrollos siempre estarán
                  alojadas en alguno de estos servicios.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={img2}
                  alt="Features split 02"
                  width={528}
                  height={396}
                />
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <h3 className=" text-color-blue fw-600 tt-u mb-8">
                  Fábrica de software especializado.
                </h3>
                {/* <h3 className="mt-0 mb-12 text-color-gold">
                  Data-driven insights
                </h3> */}
                <p className="m-0 ta-j">
                  Daxos se encarga de dar soluciones especializadas a grandes y
                  medianas empresas e industrias de diferentes sectores como los
                  bancarios, alimentos, construcción, salud entre otros,
                  complementando nuestros servicios con soporte y capacitaciones
                  de alto nivel en diferentes herramientas y servicios.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={img3}
                  alt="Features split 03"
                  width={528}
                  height={396}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
