import React from "react";
import classNames from "classnames";

const FooterSocial = ({ className, ...props }) => {
  const classes = classNames("footer-social", className);

  return (
    <div {...props} className={classes}>
      <ul className="list-reset">
        <li>
          <a
            href="https://facebook.com/Daxosline"
            rel="noopener noreferrer"
            target="_blank"
          >
            <svg width="16" height="16" viewBox="0 0 16 16">
              <title>Facebook</title>
              <path
                d="M6.023 16L6 9H3V6h3V4c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V6H13l-1 3H9.28v7H6.023z"
                fill="#fff"
              />
            </svg>
          </a>
        </li>
        <li>
          <a
            href="https:/twitter.com/Daxosline"
            rel="noopener noreferrer"
            target="_blank"
          >
            <svg width="16" height="16" viewBox="0 0 16 16">
              <title>Twitter</title>
              <path
                d="M16 3c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4C.7 7.7 1.8 9 3.3 9.3c-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H0c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4C15 4.3 15.6 3.7 16 3z"
                fill="#fff"
              />
            </svg>
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/daxosline"
            rel="noopener noreferrer"
            target="_blank"
          >
            <svg width="16" height="16" viewBox="0 0 16 16">
              <title>Instagram</title>
              <g>
                <circle cx="12.145" cy="3.892" r="1" fill="#fff" />
                <path
                  d="M8 12c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-6c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2z"
                  fill="#fff"
                />
                <path
                  d="M12 16H4c-2.056 0-4-1.944-4-4V4c0-2.056 1.944-4 4-4h8c2.056 0 4 1.944 4 4v8c0 2.056-1.944 4-4 4zM4 2c-.935 0-2 1.065-2 2v8c0 .953 1.047 2 2 2h8c.935 0 2-1.065 2-2V4c0-.935-1.065-2-2-2H4z"
                  fill="#fff"
                />
              </g>
            </svg>
          </a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/company/daxosline/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <svg
              focusable="false"
              dataIcon="linkedin-in"
              width="16"
              height="16"
              role="img"
              viewBox="0 0 448 512"
            >
              <title>Linkedin</title>
              <path
                fill="currentColor"
                d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
              ></path>
            </svg>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default FooterSocial;
