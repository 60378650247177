import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Logo from "./partials/Logo";
import FooterNav from "./partials/FooterNav";
import FooterSocial from "./partials/FooterSocial";

import daxosLogo from "../../assets/images/logo daxos letra blanca (3) (1).png";

const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool,
};

const defaultProps = {
  topOuterDivider: false,
  topDivider: false,
};

const Footer = ({ className, topOuterDivider, topDivider, ...props }) => {
  const classes = classNames(
    "site-footer center-content-mobile",
    topOuterDivider && "has-top-divider",
    className
  );

  return (
    <footer {...props} className={classes}>
      <div className="container">
        <div
          className={classNames(
            "site-footer-inner",
            topDivider && "has-top-divider"
          )}
        >
          <section className="footer-container-grid">
            <section className="footer-container-grid-left">
              <div className="footer-top space-between text-xxs">
                {/* <Logo /> */}
                <img src={daxosLogo} alt="Daxos" width="200px" />
                {/* <FooterSocial /> */}
              </div>
              <div className="footer-bottom space-between text-xxs invert-order-desktop">
                <FooterNav />
                <div className="footer-wrapper-container">
                  <p className="footer-text">
                    {/* Una empresa es una familia, en DAXOS lo sabemos, por eso
                    cuando trabajamos con nuestros aliados hacemos lo posible
                    para que vuelvan y traigan a sus amigos. */}
                  </p>
                  <div className="footer-copyright">
                    © 2021 Daxos S.A.S. All Rights Reserved.
                  </div>
                </div>
              </div>
            </section>
            <section className="footer-container-grid-middle">
              <div className="sub-group">
                {/* <h6>ADDRESS:</h6> */}
                <p>
                  <strong>ADDRESS:</strong>
                  <br /> Ak. 9 #127c-60 oficina 204 Bogotá - Colombia
                </p>
              </div>

              <div className="sub-group">
                {/* <h6>EMAIL:</h6> */}
                <p>
                  <strong>EMAIL:</strong> contact@daxosline.com
                </p>
              </div>

              <div className="sub-group">
                {/* <h6>PHONES:</h6> */}
                <p>
                  {" "}
                  <strong>PHONES:</strong> +57 (1)4660077
                </p>
              </div>
            </section>
            <section className="footer-container-grid-right">
              <FooterSocial />
              {/* <h5>Contacts</h5>

              <div className="sub-group">
                <h6>ADDRESS:</h6>
                <p> Dirección: Ak. 9 #127c-60 oficina 204 Bogotá - Colombia</p>
              </div>

              <div className="sub-group">
                <h6>EMAIL:</h6>
                <p> contact@daxosline.com</p>
              </div>

              <div className="sub-group">
                <h6>PHONES:</h6>
                <p> +57 (1)4660077</p>
              </div> */}
            </section>
          </section>
          <section className="footer-container-media"></section>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
